<template>
	<div class="main">
		<div class="tit"><span>安全设置 > 绑定手机号</span></div>
		
		<div class="form">
			<div class="li"><input type="password" v-model="password" class="input1" maxlength="16"  placeholder="请输入密码"></div>
			<div class="li"><input type="text" v-model="new_phone" class="input1" placeholder="请输入新的手机号" maxlength="11"></div>
			<div class="li verifyCode">
				<input type="text" class="input1" v-model="code" placeholder="请输入验证码" maxlength="6">
				<a  class="btnSend" @click="getCode" ref="btnSend">获取验证码</a>
			</div>
			<a  class="btn1" @click="save">保存</a>
		</div>
		
	</div>
</template>
<script type="text/javascript">
	import icom from '@/common/com.js';
	import utils from '@/common/utils.js';
	import useStore from '@/store/index.js'
	export default {
		data(){
			return {
				new_phone:'',
				password:'',
				code:'',
				countDownTime:'获取验证码',
				logins:{}
			}
		},
		created(){
			this.store = useStore()
			this.logins = this.store.logins;
		},
		components:{
		},
		methods:{
			save(){
				if( this.password==''){
					this.store.showToast('请输入密码')
					return false
				}
				let isPhone = this.checkPhone();
				if(!isPhone) return
				
				if(this.code==''){
					this.store.showToast('请输入验证码')
					return
				}
				
				this.API.update_phone({password:this.password,new_phone:this.new_phone,code:this.code},(res)=>{
					utils.get_info(this.API,this.store)
					this.$router.push('/person/infoset');
				})
				// 返回设置页面
				
				
			},
			getCode(){
				let isPhone = this.checkPhone();
				if(!isPhone) return
				// 1：注册；2：忘记密码 3：修改密码 4 更改绑定手机号
				this.API.send_msg_code({phone:this.new_phone,source:4},(res)=>{
					icom.countdown(this.$refs.btnSend,60);
				})
				
			},
			checkPhone(){
				if(this.new_phone==''){
					this.store.showToast('请输入手机号')
					return false
				}
				// 未注册时候
				// 该手机号未注册
				if(!icom.checkStr(this.new_phone)){
					this.store.showToast('请输入正确的手机号')
					return false
				}
				console.log(this.logins)
				if(this.new_phone ==this.logins.loginInfo.fullPhone){
					this.store.showToast('该手机号为当前绑定的手机号')
					return false
				}
			
				
				
				return true
			},
			
		}
	}
</script>
<style type="text/css" scoped="">
	.main{flex: 1;background: #FFFFFF;border-radius: 4px;padding: 20px 25px;}
	.form {width: 500px;margin-left: 35px;margin-top: 4px;}
	.form .li {margin-top: 28px;}
	.form .input1 {width: 100%;height: 44px;border-radius: 6px;border: 1px solid #E3E6EC;padding: 0 26px;font-size: 16px;}
	.form .verifyCode {height: auto;overflow: hidden;}
	.form .verifyCode .input1 {width: 318px;float: left;}
	.form .verifyCode .btnSend {width: 143px;height: 44px;background: #F5630B;border-radius: 6px;float: right;;font-size: 16px;color: #fff;text-align: center;line-height: 44px;}
	.form .verifyCode .btnSend.disabled {background: #F3F3F6;color: #222;}
	.form .btn1 {width: 360px;height: 44px;background: #F5630B;border-radius: 6px;line-height: 44px;text-align: center;color: #fff;font-size: 18px;margin: 37px auto 0;display: block;}
</style>